<template>
    <div>
        <v-container>
    <v-row align-content="center" align="middle">
    <v-col>
        <v-text-field v-model="password" type="password"
                      label="Password" :error="password!==password2"/>
    </v-col>
    <v-col>
        <v-text-field v-model="password2" type="password"
                      label="Password again" :error="password!==password2"/>
    </v-col>
    </v-row>
    <v-btn color="blue darken-1" text @click="save" :disabled="password!==password2">Save</v-btn>
        </v-container>
    </div>
</template>

<script>
    import {default as userApi} from '@/apis/user.ts'
    export default {
        name: "Pw.vue",
        data: function () {
            return {
                error: null,
                password: null,
                password2: null
            }
        },
        methods: {
            save() {
                userApi.updatePw(this.password)
            }
        }
    }
</script>

<style scoped>

</style>